<template>
  <div class="week-deal-container">
    <!-- < v-if="areaData == 1"> -->
    <div v-show="areaData == 1">
      <div class="title1">日客流量</div>
      <div class="line"></div>

      <div v-show="length != 0">
        <div
          id="day_passenger_echarts"
          style=" width: 7.725rem;
          height: 3.4rem"
          ref="day_passenger_echarts"
        ></div>
      </div>
      <div v-show="length == 0" class="none">
        <el-image
          src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
        ></el-image>
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import { passengerNuml2Serve } from "./api";
import moment from "moment";

export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    areaData: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      length: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.iniChart();
    });
    this.timer = setInterval(() => {
      // 要执行的函数
      this.iniChart();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async iniChart() {
      console.log(this.areaData);
      const { code, data } = await passengerNuml2Serve({
        action: "flow.trend.get",
        type: this.areaData,
      });
      this.length = data.length;
      if (code == 0 && data.length > 0) {
        var mychart22 = this.$echarts.init(this.$refs.day_passenger_echarts);
        var flow = data.map((item) => item.flow*1);
        var option = {
          color: ["rgba(255,245,14,0.8) 0%", "rgba(74,235,255,0) 100%)"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          xAxis: [
            {
              type: "category",
              data:
                this.areaData == 1
                  ? data.map((item) => moment.unix(item.time).format("HH:mm"))
                  : data.map((item) => moment.unix(item.time).format("MM/DD")),

              axisLabel: {
                color: "#fff",
                textStyle: {
                  fontSize: "18",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#376CFF",
                },
              },

              splitLine: {
                show: false,
                lineStyle: {
                  color: "blue",
                  width: 1,
                  type: "solid",
                },
              },
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                color: "#fff",
                textStyle: {
                  fontSize: "18",
                },
              },
              minInterval: 1,
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#1F297B",
                },
              },
              splitLine: {
                show: false,
              },

              nameTextStyle: {
                rich: {
                  legend: {
                    width: 12,
                    height: 4,
                    backgroundColor: "376CFF",
                  },
                  value: {
                    color: "#fff",
                  },
                },
              },
            },
          ],
          series: [
            {
              name: "折线图",
              type: "line",
              smooth: true,
              data: flow,
              itemStyle: {
                color: "#6AE2A6",
              },
              areaStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: " rgba(45,166,79)",
                  },
                  {
                    offset: 1,
                    color: "rgba(4,4,194,0)",
                  },
                ]),
              },
              showSymbol: false,
            },
          ],
        }
        mychart22.setOption(option)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.week-deal-container {
  background: url(https://www.chuansmart.com/storage/screen/div-bg.png)
    no-repeat;
  background-size: 7.725rem 3.975rem;
  width: 7.725rem;
  height: 3.975rem;
  margin-bottom: 8px;
  .title1 {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 12px;
  }
  .line {
    width: 430px;
    height: 2px;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 12px auto 0;
  }
  .deal-content {
    width: 3rem;
    height: 2rem;
    transition: all 1.5s;
    background: url(https://www.chuansmart.com/storage/screen/deal-icon.png)
      no-repeat;
    background-size: 3rem 2rem;
    position: relative;
    .num {
      font-size: 36px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #41fe93;
      text-align: center;
      position: absolute;
      top: 50px;
      width: 100%;
    }
    .text {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 90px;
      width: 100%;
    }
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 180px;
      height: 100px;
    }
  }
}
</style>
