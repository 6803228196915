import request from "@/utils/request";

//数据屏模板2 质检信息
export function quality2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}
//数据屏模板2 检测项目占比
export function qualityCate2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}
//数据屏模板2 今日数据
export function todayData2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}
//数据屏模板2 市场汇总
export function marketCollect2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}
//数据屏模板2 业态占比
export function areaProportion2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}

//数据屏模板2 台账
export function standingBook2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}

//数据屏模板2 品类销售排行
export function cateSalesServe(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}

//数据屏模板2 商户销量排行
export function salesRankServe(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}

//数据屏模板2 星级商户排行
export function startMerch2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}

//数据屏模板2 周交易趋势
export function weekDeal2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}

//数据屏模板2 客流量
export function passengerNuml2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}

//数据屏模板2 今日业态销售排行
export function areaSales2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}

//数据屏模板2 商品销售排行
export function productRank2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}
