import request from "@/utils/request";

//数据屏模板2 今日业态销售排行
export function areaSales2Serve(data) {
  return request({
    url: "/api/screen/order/report",
    method: "post",
    data,
  });
}
