import request from "@/utils/request";

//数据屏模板2 市场信息
export function screenTemplate2Serve(data) {
  return request({
    url: "/api/screen/client/main",
    method: "post",
    data,
  });
}

//数据屏模板2 市场信息
export function screenWeather2Serve(data) {
  return request({
    url: "/api/screen/client/main",
    method: "post",
    data,
  });
}
