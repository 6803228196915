var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"start-merchant-container"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title1"},[_vm._v("星级商户排名")]),_c('div',{staticClass:"line"}),(_vm.list.length)?_c('el-carousel',{attrs:{"direction":"vertical","autoplay":true,"interval":10000,"indicator-position":"none"}},_vm._l((_vm.list),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},_vm._l((item.item),function(c,d){return _c('div',{key:d,staticClass:"one"},[_c('div',{staticClass:"image"},[(c.img_url)?_c('img',{attrs:{"src":c.img_url}}):_c('div')]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(c.orgz_name))]),_c('div',{staticClass:"start"},_vm._l((c.star),function(q,w){return _c('img',{key:w,attrs:{"src":"https://www.chuansmart.com/storage/screen/start-merchant-icon.png"}})}),0)]),_c('div',{staticClass:"index",class:c.no == 1
                    ? 'index'
                    : c.no == 2
                    ? 'two1'
                    : c.no == 3
                    ? 'three1'
                    : 'four1'},[_vm._v(" "+_vm._s(c.no)+" ")])])}),0)])])}),1):_c('div',{staticClass:"none"},[_c('el-image',{attrs:{"src":"https://www.chuansmart.com/storage/screen/newscreen-none.png"}}),_c('div',[_vm._v("暂无数据")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }