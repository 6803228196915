<template>
  <div class="test-item-container">
    <div class="box">
      <div class="title1">检测项目占比</div>
      <div class="line"></div>
      <div class="content" v-if="quality_data.length != 0">
        <div class="left-label">
          <ul>
            <li v-for="(item, index) in quality_data" :key="index">
              <div
                :class="
                  index == 0
                    ? 'div1'
                    : index == 1
                    ? 'div2'
                    : index == 2
                    ? 'div3'
                    : index == 3
                    ? 'div4'
                    : 'div5'
                "
              ></div>
              {{ item.name | ellipsis }}
              {{ item.value }}%
            </li>
            <!-- <li>
              <div class="div2"></div>
              水果 17%
            </li>
            <li>
              <div class="div3"></div>
              海货 20%
            </li>
            <li>
              <div class="div4"></div>
              水产 22%
            </li>
            <li>
              <div class="div5"></div>
              豆制品 28%
            </li> -->
          </ul>
        </div>
        <pic-chart
          :optionData="quality_data"
          v-if="quality_data.length > 0"
          ref="picChart"
        />
      </div>
      <div v-else class="none">
        <el-image
          src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
        ></el-image>
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import picChart from "@/components/pieChart.vue";
import { qualityCate2Serve } from "./api";
export default {
  name: "",
  components: { picChart },
  mixins: [],
  props: {
    areaData: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      config: {
        color: ["#3ED7FF"],
        radius: "65%",
        activeRadius: "75%",
        lineWidth: 30,
        // animationCurve: "easeInOutElastic",
      },
      quality_data: [],
    };
  },
  filters: {
    // 超过20位显示...
    ellipsis: function(value) {
      if (!value) return "";
      if (value.length > 8) {
        return value.slice(0, 8) + "...";
      }
      return value;
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.getTestItem();
    this.timer = setInterval(() => {
      // 要执行的函数
      this.quality_data = [];
      this.$nextTick(() => {
        this.$refs.picChart.initChart();
      });
      this.getTestItem();
    }, 900000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async getTestItem() {
      const { code, data } = await qualityCate2Serve({
        action: "quality.cate.get",
        quality_type: this.areaData,
        limit: 5,
      });
      if (code == 0 && data.length > 0) {
        const arr = data.map((item) => {
          return {
            name: item.c_name,
            value: item.rate,
          };
        });
        console.log(arr);
        this.quality_data = arr.length > 5 ? arr.splice(0, 5) : arr;
        console.log(this.quality_data, "this.quality_data");
        this.$nextTick(() => {
          this.$refs.picChart.initChart();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.test-item-container {
  .box {
    background: url(https://www.chuansmart.com/storage/screen/div-bg.png)
      no-repeat;
    background-size: 7.725rem 3.975rem;
    width: 7.725rem;
    height: 3.975rem;
    margin-bottom: 8px;
    .title1 {
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #94fffc;
      text-align: center;
      padding-top: 12px;
    }
    .line {
      width: 430px;
      height: 2px;
      background: linear-gradient(
        116deg,
        #070870 0%,
        #5377ff 47%,
        #070870 100%
      );
      margin: 12px auto 0;
    }
    .content {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      width: 7.725rem;
      position: relative;
      .left-label {
        width: 5.875rem;
        position: absolute;
        top: 60px;
        left: 30px;
        ul {
          li {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 20px;
            div {
              width: 14px;
              height: 14px;
              margin-right: 4px;
            }

            .div1 {
              background: #3d6ff2;
            }
            .div2 {
              background: #3ed7ff;
            }
            .div3 {
              background: #365dfe;
            }
            .div4 {
              background: #6ecdff;
            }
            .div5 {
              background: #6edbe6;
            }
          }
        }
      }
    }
    .none {
      padding-top: 1rem;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1530a7;
      .el-image {
        width: 180px;
        height: 100px;
      }
    }
  }
}
</style>
<style>
.dv-active-ring-chart .active-ring-info .active-ring-name {
  overflow: visible;
  margin-top: 10px;
}
</style>
