var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-screen-bg"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"le"},[_vm._v(" "+_vm._s(_vm.weather_info.city)+" "+_vm._s(_vm.weather_info.degree)+" "+_vm._s(_vm.weather_info.weather + " |")+" "+_vm._s(_vm.weather_info.wind_direction)+" ")]),_c('div',{staticClass:"cen"},[_vm._v(" "+_vm._s(_vm.screen2Info.title)+" ")]),_c('div',{staticClass:"ri"},[_vm._v(_vm._s(_vm.dateYear)+" "+_vm._s(_vm.dateWeek)+" "+_vm._s(_vm.dateDay))])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area1_time,"autoplay":_vm.screen2Info.area1.is_roll == 1,"initial-index":0},on:{"change":(value) => _vm.area1Change(value, _vm.screen2Info.area1.area_list)}},_vm._l((_vm.screen2Info.area1.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area1_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area1_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area1_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area1_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area1_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area1_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area1_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area1_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area1.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area1_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area1_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area1_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area1_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area1_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area1_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area1_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area1_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area1_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area1_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area1_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area1_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area1_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area1_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area2_time,"autoplay":_vm.screen2Info.area2.is_roll == 1,"initial-index":0},on:{"change":(value) => _vm.area2Change(value, _vm.screen2Info.area2.area_list)}},_vm._l((_vm.screen2Info.area2.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area2_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area2_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area2_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area2_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area2_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area2_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area2_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area2_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area2.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area2_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area2_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area2_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area2_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area2_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area2_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area2_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area2_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area2_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area2_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area2_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area2_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area2_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area2_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area3_time,"autoplay":_vm.screen2Info.area3.is_roll == 1,"initial-index":0},on:{"change":(value) => _vm.area3Change(value, _vm.screen2Info.area3.area_list)}},_vm._l((_vm.screen2Info.area3.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area3_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area3_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area3_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area3_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area3_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area3_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area3_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area3_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area3.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area3_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area3_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area3_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area3_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area3_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area3_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area3_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area3_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area3_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area3_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area3_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area3_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area3_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area3_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area4_time,"autoplay":_vm.screen2Info.area4.is_roll == 1,"initial-index":0},on:{"change":(value) => _vm.area4Change(value, _vm.screen2Info.area4.area_list)}},_vm._l((_vm.screen2Info.area4.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area4_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area4_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area4_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area4_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area4_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area4_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area4_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area4_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area4.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area4_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area4_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area4_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area4_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area4_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area4_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area4_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area4_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area4_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area4_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area4_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area4_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area4_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area4_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area4_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area4_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area4_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area5_time,"autoplay":_vm.screen2Info.area5.is_roll == 1,"initial-index":0},on:{"change":(value) => _vm.area5Change(value, _vm.screen2Info.area5.area_list)}},_vm._l((_vm.screen2Info.area5.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area5_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area5_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area5_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area5_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area5_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area5_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area5_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area5_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area5.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area5_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area5_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area5_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area5_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area5_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area5_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area5_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area5_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area5_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area5_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area5_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area5_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area5_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area5_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area6_time,"autoplay":_vm.screen2Info.area6.is_roll == 1,"initial-index":0},on:{"change":(value) => _vm.area6Change(value, _vm.screen2Info.area6.area_list)}},_vm._l((_vm.screen2Info.area6.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area6_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area6_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area6_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area6_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area6_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area6_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area6_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area6_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area6.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area6_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area6_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area6_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area6_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area6_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area6_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area6_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area6_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area6_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area6_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area6_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area6_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area6_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area6_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area7_time,"initial-index":0,"autoplay":_vm.screen2Info.area7.is_roll == 1},on:{"change":(value) => _vm.area7Change(value, _vm.screen2Info.area7.area_list)}},_vm._l((_vm.screen2Info.area7.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area7_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area7_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area7_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area7_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area7_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area7_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area7_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area7_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area7.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area7_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area7_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area7_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area7_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area7_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area7_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area7_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area7_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area7_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area7_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area7_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area7_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area7_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area7_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area8_time,"autoplay":_vm.screen2Info.area8.is_roll == 1,"initial-index":0},on:{"change":(value) => _vm.area8Change(value, _vm.screen2Info.area8.area_list)}},_vm._l((_vm.screen2Info.area8.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area8_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area8_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area8_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area8_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area8_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area8_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area8_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area8_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area8.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area8_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area8_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area8_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area8_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area8_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area8_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area8_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area8_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area8_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area8_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area8_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area8_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area8_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area8_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"box"},[_c('el-carousel',{attrs:{"indicator-position":"none","interval":_vm.area9_time,"autoplay":_vm.screen2Info.area9.is_roll == 1,"initial-index":0},on:{"change":(value) => _vm.area9Change(value, _vm.screen2Info.area9.area_list)}},_vm._l((_vm.screen2Info.area9.area_list),function(item,index){return _c('el-carousel-item',{key:index},[(item.resource == 1 && _vm.area9_index == index)?_c('todayData',{ref:"todayData",refInFor:true}):_vm._e(),(item.resource == 2 && _vm.area9_index == index)?_c('marketCollect',{ref:"marketCollect",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 3 && _vm.area9_index == index)?_c('qualityTesting',{ref:"qualityTesting",refInFor:true}):_vm._e(),(item.resource == 4 && _vm.area9_index == index)?_c('areaProportion',{ref:"areaProportion",refInFor:true}):_vm._e(),(item.resource == 5 && _vm.area9_index == index)?_c('testItem',{ref:"testItem",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 6 && _vm.area9_index == index)?_c('specialPrice',{ref:"specialPrice",refInFor:true}):_vm._e(),(item.resource == 7 && _vm.area9_index == index)?_c('imageData',{ref:"imageData",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 8 && _vm.area9_index == index)?_c('videoData',{ref:"videoData",refInFor:true,attrs:{"areaData":item.relation,"is_roll":_vm.screen2Info.area9.is_roll == 1 ? true : false}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 1 &&
                  _vm.area9_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 2 &&
                  _vm.area9_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 3 &&
                  _vm.area9_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 4 &&
                  _vm.area9_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 9 &&
                  item.relation == 5 &&
                  _vm.area9_index == index
              )?_c('standingBook',{ref:"standingBook",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 10 && _vm.area9_index == index)?_c('areaSales',{ref:"areaSales",refInFor:true}):_vm._e(),(item.resource == 11 && _vm.area9_index == index)?_c('productRank',{ref:"productRank",refInFor:true}):_vm._e(),(item.resource == 12 && _vm.area9_index == index)?_c('cateSales',{ref:"cateSales",refInFor:true}):_vm._e(),(item.resource == 13 && _vm.area9_index == index)?_c('salesRank',{ref:"salesRank",refInFor:true}):_vm._e(),(item.resource == 14 && _vm.area9_index == index)?_c('realTrade',{ref:"realTrade",refInFor:true}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 1 &&
                  _vm.area9_index == index
              )?_c('dayDeal',{ref:"dayDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 2 &&
                  _vm.area9_index == index
              )?_c('weekDeal',{ref:"weekDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 15 &&
                  item.relation == 3 &&
                  _vm.area9_index == index
              )?_c('monthDeal',{ref:"monthDeal",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(item.resource == 16 && _vm.area9_index == index)?_c('startMerchant',{ref:"startMerchant",refInFor:true}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 1
              )?_c('passengerNumDay',{ref:"passengerNumDay",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 2
              )?_c('passengerNumWeek',{ref:"passengerNumWeek",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e(),(
                item.resource == 17 &&
                  _vm.area1_index == index &&
                  item.relation == 3
              )?_c('passengerNumMonth',{ref:"passengerNumMonth",refInFor:true,attrs:{"areaData":item.relation}}):_vm._e()],1)}),1)],1)]),_c('div',[(_vm.screen2Info.is_show_notice == 1)?_c('div',{staticClass:"notic"},[(_vm.screen2Info.is_show_notice == 1)?_c('marquee',{staticClass:"notic",attrs:{"direction":"left"}},[_vm._v(" "+_vm._s(_vm.screen2Info.notice)+" ")]):_vm._e()],1):_c('div',{staticClass:"notic"},[_vm._v(" 上海传蔬信息提供技术支持 电话：400-135-9365 ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }