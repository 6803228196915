<!-- 业态占比 -->
<template>
  <div class="area-proportion-container">
    <div class="title1">市场业态占比</div>
    <div class="line"></div>

    <div v-show="targetData.length != 0">
      <div
        id="area_echarts"
        style=" width: 7.725rem;
          height: 3.4rem;"
        ref="area_echarts"
      ></div>
    </div>
    <div v-show="targetData.length == 0" class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { areaProportion2Serve } from "./api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      targetData: [],
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.$nextTick(() => {
      this.iniChart();
    });
    this.timer = setInterval(() => {
      // 要执行的函数
      this.iniChart();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async iniChart() {
      const { code, data } = await areaProportion2Serve({
        action: "floor.get",
      });
      if (code == 0 && data.length > 0) {
        this.targetData = data.map((item) => {
          return {
            name:
              item.name.length > 4 ? item.name.slice(0, 5) + "..." : item.name,
            value: item.rate,
          };
        });
        console.log(this.targetData);
        var showData = [];
        var sum = 0,
          max = 0;
        this.targetData.forEach((item) => {
          sum += item.value;
          if (item.value >= max) max = item.value;
        });
        // 放大规则
        var number = Math.round(max * 0.9);
        showData = this.targetData.map((item) => {
          return {
            value1: item.value,
            value: number + item.value,
            name: item.name,
          };
        });
        console.log(showData, "showData");
      }
      //放大值
      // var pieData = this.targetData.slice(0, 6);
      this.chart = this.$echarts.init(this.$refs.area_echarts);
      this.chart.setOption({
        color: [
          "#FF7DEE",
          "#0062FF",
          "#2EF4E6",
          "#38D565",
          "#FFB834 ",
          "#8AA9FF",
          "#DA4141",
          "#E550FF",
        ],
        tooltip: {
          trigger: "item",
        },

        legend: {
          orient: "vertical",
          top: "center",
          //   right: 10,
          left: 24,
          textStyle: {
            color: "#fff",
            fontSize: 18,
          },
          formatter: function(name) {
            return name;
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "70%",
            minAngle: 10,
            roseType: "radius",
            center: ["60%", "50%"],
            itemStyle: {
              borderRadius: 5,
            },
            data: showData,
            label: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: 18,
              },
              formatter(param) {
                // correct the percentage
                return param.name + " (" + param.data.value1 + "%)";
              },
            },
            labelLine: {
              show: true,
              normal: {
                lineStyle: {
                  color: "#27459E", // 改变标示线的颜色
                },
              },
            },
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.area-proportion-container {
  margin-bottom: 8px;
  .title1 {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 12px;
  }
  .line {
    width: 430px;
    height: 2px;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 12px auto 0;
  }
  .deal-content {
    width: 3rem;
    height: 2rem;
    transition: all 1.5s;
    background: url(https://www.chuansmart.com/storage/screen/deal-icon.png)
      no-repeat;
    background-size: 3rem 2rem;
    position: relative;
    .num {
      font-size: 36px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #41fe93;
      text-align: center;
      position: absolute;
      top: 50px;
      width: 100%;
    }
    .text {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 90px;
      width: 100%;
    }
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 180px;
      height: 100px;
    }
  }
}
</style>
