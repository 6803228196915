<template>
  <div class="sales-rank-container">
    <div class="title1">商户销量排行</div>
    <div class="line"></div>

    <!-- <div v-show="length != 0"> -->
    <div class="content" v-show="length != 0">
      <dv-scroll-ranking-board :config="config" v-if="config.data.length > 0" />
    </div>
    <!-- </div> -->
    <div v-show="length == 0" class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { salesRankServe } from "./api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      config: {
        waitTime: 9000,
        data: [],
      },
      length: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.getSalesRank();
    });
    this.timer = setInterval(() => {
      // 要执行的函数
      this.getSalesRank();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async getSalesRank() {
      const { code, data } = await salesRankServe({
        action: "merchant.list",
        limit: 10,
      });

      this.length = data.length;
      if (code == 0 && data.length > 0) {
        // this.config.data = [{ name: "1", value: 1 }];

        this.config.data = data.map((item) => {
          return {
            name: item.orgz_name,
            value: item.order_num,
          };
        });
        // ...this.config,
        // console.log(this.config, "12121212");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sales-rank-container {
  .title1 {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 12px;
  }
  .line {
    width: 430px;
    height: 2px;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 12px auto 0;
  }
  .content {
    padding: 7px 25px 0;
    overflow: hidden;
    height: 267px;
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 180px;
      height: 100px;
    }
  }
}
</style>
<style lang="scss">
.sales-rank-container {
  .dv-scroll-ranking-board .row-item {
  }
  .dv-scroll-ranking-board .row-item:nth-child(1) {
    .ranking-column .inside-column {
      background: linear-gradient(90deg, #d02c24 0%, #f47979 100%);
      border-radius: 10px;
      height: 15px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(2) {
    .ranking-column .inside-column {
      background: linear-gradient(270deg, #ffcc87 0%, #ff7629 100%);
      border-radius: 10px;
      height: 15px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(3) {
    .ranking-column .inside-column {
      background: linear-gradient(90deg, #fad53a 0%, #f9eaa3 100%);
      border-radius: 10px;
      height: 15px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(4) {
    .ranking-column .inside-column {
      background: linear-gradient(90deg, #5dc067 0%, #b6f2c1 100%);
      border-radius: 10px;
      height: 15px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(5) {
    .ranking-column .inside-column {
      background: linear-gradient(270deg, #7ce3ff 0%, #2133e7 100%);
      border-radius: 10px;
      height: 15px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(6) {
    .ranking-column .inside-column {
      background: linear-gradient(90deg, #d02c24 0%, #f47979 100%);
      border-radius: 10px;
      height: 15px;
    }
  }
  .ranking-info {
    font-size: 0.225rem !important;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
  }
  .dv-scroll-ranking-board .ranking-info .info-name {
    margin-left: 0.1rem;
  }
  //   .dv-scroll-ranking-board .row-item:nth-child(4) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #5dc067 0%, #b6f2c1 100%);
  //       border-radius: 10px;
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(5) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(270deg, #7ce3ff 0%, #2133e7 100%);
  //       border-radius: 10px;
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(6) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #d02c24 0%, #f47979 100%);
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(6) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #d02c24 0%, #f47979 100%);
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(7) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(270deg, #ffcc87 0%, #ff7629 100%);
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(8) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #fad53a 0%, #f9eaa3 100%);
  //       border-radius: 10px;
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(9) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #5dc067 0%, #b6f2c1 100%);
  //       border-radius: 10px;
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(10) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(270deg, #7ce3ff 0%, #2133e7 100%);
  //       border-radius: 10px;
  //     }
  //   }
}
</style>
