<template>
  <div class="image-data-container">
    <el-carousel indicator-position="none" v-if="image_arr.length != 0">
      <el-carousel-item v-for="(item, index) in image_arr" :key="index">
        <el-image :src="item.url"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div v-else class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    areaData: {
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      type: Array,
    },
  },
  data() {
    return {
      image_arr: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    console.log(this.areaData);
    this.image_arr = this.areaData;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.image-data-container {
  width: 7.725rem;
  height: 3.975rem;
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 180px;
      height: 100px;
    }
  }
}
</style>
