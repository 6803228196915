<template>
  <div class="start-merchant-container">
    <div class="box">
      <div class="title1">星级商户排名</div>
      <div class="line"></div>
      <el-carousel
        direction="vertical"
        :autoplay="true"
        :interval="10000"
        v-if="list.length"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="content">
            <div class="left">
              <div class="one" v-for="(c, d) in item.item" :key="d">
                <div class="image">
                  <img :src="c.img_url" v-if="c.img_url" />
                  <div v-else></div>
                </div>
                <div class="info">
                  <div class="name">{{ c.orgz_name }}</div>
                  <div class="start">
                    <img
                      src="https://www.chuansmart.com/storage/screen/start-merchant-icon.png"
                      v-for="(q, w) in c.star"
                      :key="w"
                    />
                  </div>
                </div>
                <div
                  class="index"
                  :class="
                    c.no == 1
                      ? 'index'
                      : c.no == 2
                      ? 'two1'
                      : c.no == 3
                      ? 'three1'
                      : 'four1'
                  "
                >
                  {{ c.no }}
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-else class="none">
        <el-image
          src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
        ></el-image>
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import { startMerch2Serve } from "./api";

export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      leftList: [],
      rightList: [],
      list: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getStartMerch();
    this.timer = setInterval(() => {
      // 要执行的函数
      this.getStartMerch();
    }, 900000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async getStartMerch() {
      const { code, data } = await startMerch2Serve({ action: "start.get" });
      if (code == 0 && data.length > 0) {
        var arr = [];
        for (var i = 0; i <= data.length; i += 6) {
          arr.push(data.slice(i, i + 6));
        }
        console.log(arr, "arr");
        arr = arr.map((item, index) => {
          return {
            item: item.length != 6 ? item.concat(arr[0]).slice(0, 6) : item,
          };
        });
        console.log(arr, "1212121");
        this.list = arr;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.start-merchant-container {
  .box {
    background: url(https://www.chuansmart.com/storage/screen/div-bg.png)
      no-repeat;
    background-size: 7.725rem 3.975rem;
    width: 7.725rem;
    height: 3.975rem;
    margin-bottom: 8px;
    .title1 {
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #94fffc;
      text-align: center;
      padding-top: 12px;
    }
    .line {
      width: 430px;
      height: 2px;
      background: linear-gradient(
        116deg,
        #070870 0%,
        #5377ff 47%,
        #070870 100%
      );
      margin: 12px auto 0;
    }
    .content {
      display: flex;
      justify-content: space-around;
      margin: 16px 30px 0;
      .left,
      .right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .one {
          width: 264px;
          height: 72px;
          background: rgba(41, 49, 152, 0.47);
          box-shadow: inset 0px 1px 2px 0px rgba(61, 52, 255, 0.5),
            inset 0px -1px 2px 0px rgba(74, 66, 255, 0.5);
          filter: blur(0px);
          margin-top: 8px;
          display: flex;
          align-items: center;
          padding-left: 8px;
          position: relative;
          .image {
            img {
              width: 56px;
              height: 56px;
              border-radius: 4px;
              border: 1px solid #011167;
            }
            div {
              width: 56px;
              height: 56px;
              border-radius: 4px;
              border: 1px solid #011167;
            }
          }
          .info {
            margin-left: 8px;
            .name {
              font-size: 16px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #ffffff;
              margin-bottom: 12px;
            }
            .start {
              img {
                width: 20px;
                height: 20px;
                margin-right: 4px;
              }
              div {
                width: 56px;
                height: 56px;
                border-radius: 4px;
                border: 1px solid #011167;
              }
            }
          }
          .index {
            position: absolute;
            top: 0;
            right: 0;
            width: 34px;
            height: 24px;
            background: #d0452c;
            border-radius: 0px 0px 0px 12px;
            text-align: center;
            line-height: 24px;
            font-size: 16px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #ffffff;
          }
          .two1 {
            background: #ea810a;
          }
          .three1 {
            background: #ffce00;
          }
          .four1 {
            background: #3436a4;
          }
        }
      }
    }
    .none {
      padding-top: 1rem;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1530a7;
      .el-image {
        width: 180px;
        height: 100px;
      }
    }
  }
}
</style>
