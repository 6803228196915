import { render, staticRenderFns } from "./monthDeal.vue?vue&type=template&id=0d21c4de&scoped=true"
import script from "./monthDeal.vue?vue&type=script&lang=js"
export * from "./monthDeal.vue?vue&type=script&lang=js"
import style0 from "./monthDeal.vue?vue&type=style&index=0&id=0d21c4de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d21c4de",
  null
  
)

export default component.exports