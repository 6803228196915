import axios from "axios";
import route from "@/router";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.data["uuid"] = sessionStorage.getItem("uuid");

    // do something before request is sent

    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error).catch(() => {});
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (+res.code != 0) {
      //   Message({
      //     message: res.msg || "Error",
      //     type: "error",
      //     duration: 3 * 1000,
      //   });

      //   if (res.code == -22222) {
      //     sessionStorage.clear();
      //     // location.reload();
      //     // location.reload();
      //     console.log(route);
      //     route.push({ name: "login" });
      //   }

      return Promise.reject(response).catch((res) => {
        // console.log(res);
        return res.data;
      });

      // return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 3 * 1000,
    // });
    return Promise.reject(error).catch(() => {});
  }
);

export default service;
