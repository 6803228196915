<template>
  <div class="special-testing-container">
    <div class="title1">特价公示</div>
    <div class="line"></div>

    <div v-if="config.data.length != 0">
      <div class="content">
        <dv-scroll-board :config="config" />
      </div>
    </div>
    <div v-else class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { quality2Serve } from "./api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      config: {
        header: ["商品", "单位", "原价", "现价"],
        data: [],
        oddRowBGC: "#101349",
        evenRowBGC: "#111753",
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getQualityList();
    this.timer = setInterval(() => {
      // 要执行的函数
      this.getQualityList();
    }, 900000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async getQualityList() {
      const { code, data } = await quality2Serve({
        action: "cate.list",
      });
      if (code == 0 && data.length > 0) {
        const target = data.map((c) => {
          const { category, unit_name, price_range_e, price_range_s } = c;
          console.log(c);
          return [
            '<span style="font-size:0.3rem">' + category + "</span>",
            '<span style="font-size:0.3rem">' + unit_name + "</span>",
            '<span style="font-size:0.3rem">' + price_range_e + "</span>",
            '<span style="font-size:0.3rem">' + price_range_s + "</span>",
          ];
        });
        this.config.data = target;
        console.log(this.config);
        this.config = {
          data: target,
          oddRowBGC: "#101349",
          evenRowBGC: "#111753",
          header: ["商品", "单位", "原价", "现价"],

          // header: ["时间", "商户", "铺位号", "交易金额"],
          // // index: true,
          // headerHeight: this.FontChart(0.4),
          // headerBGC: "rgb(4, 7, 138, 0)", //表头
          // oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
          // evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
          // columnWidth: [100],
          align: ["center", "center", "center", "center", "center"],
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.special-testing-container {
  margin-bottom: 8px;
  .title1 {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 12px;
  }
  .line {
    width: 430px;
    height: 2px;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 12px auto 0;
  }
  .content {
    padding: 7px 25px 0;
    overflow: hidden;
    height: 267px;
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 180px;
      height: 100px;
    }
  }
}
</style>
<style lang="scss">
.dv-scroll-board .header {
  // opacity: 0 !important;
  background: #16174a !important;
  border: 0 !important;
}
.dv-scroll-board .header .header-item {
  font-size: 16px !important;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
  font-weight: 500 !important;
  color: #94fffc !important;
  width: 100% !important;
}
.dv-scroll-board .rows .ceil {
  padding: 0;
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
.dv-scroll-board .rows .row-item {
  height: 40px;
  line-height: 40px;
}
</style>
